<template>
  <div>
    <!-- Filters -->
    <filter-member
      :clubs="clubs"
      :countries="countries"
      :states="states"
      :categories="categories"
      :player-statuses="playerStatuses"
      :staff-job-positions="staffJobPositions"
      :check-permission="checkPermission"
      :option-active-d="optionActiveD"
      :select-player="selectPlayer"
      :select-coaching-staff="selectCoachingStaff"
      @filter-query="handleFilters"
    />
    <!--/ Filters -->
    <b-card class="mb-3">
      <!-- Tab -->
      <b-row class="mb-0 pb-0">
        <b-col class="col-12 justify-content-end d-md-inline-flex d-none">
          <ul class="navs float-right pb-0 mb-0">
            <li
              v-for="sectionC in typeMember"
              :key="sectionC.id"
              class="i-li-active"
              :class="{'i-tab-option-active' : sectionC.query == optionActiveD}"
              @click="assignRouteY(sectionC.query)"
            >
              <span class="text-dark">
                {{ $t(sectionC.name) }}
              </span>
            </li>
          </ul>
        </b-col>
        <b-col class="col-md-12 justify-content-end d-block d-sm-block d-md-none">
          <div class="i-show-drop float-right mb-1">
            <div class="i-dropdown">
              <button class="i-drop-section-button">
                {{ sectionName }}
                <span style="margin-left: 5px">
                  <feather-icon
                    icon="ChevronDownIcon"
                    size="16"
                    class="rotates"
                  />
                </span>
              </button>
              <div class="i-dropdown-content">
                <div
                  v-for="sectionD in typeMember"
                  :key="sectionD.id"
                  :class="sectionD.query == optionActiveD ? 'i-nav-section-active' : ''"
                  @click="assignRouteY(sectionD.query)"
                >
                  {{ $t(sectionD.name) }}
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="mb-0">
        <b-row>
          <b-col
            v-if="checkPermission(['CREATE_MEMBERS_PLAYERS', 'CREATE_MEMBERS_COACHING_STAFF', 'ALLOW_ALL'])"
          >
            <div class="d-flex justify-content-end">
              <span class="mr-20">{{ $t('member.create') }}</span>
              <select-modal
                :check-permission="checkPermission"
                :members-types="membersTypes"
                :color-primary-btn="colorPrimaryBtn"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <app-collapse
        :type="collapseType"
      >
        <!-- accordion -->
        <app-collapse-item
          :title="$t('pending-members')"
          :sub-title="$t('incomplete-records')"
          :total-recors="`(${membersIncompleteDataList.total})`"
        >
          <div v-if="optionActiveD == selectPlayer">
            <b-overlay
              :show="isLoadingIncompleted"
              opacity="1"
              :class="isLoadingIncompleted ? 'p-2 mb-1 mt-1' : ''"
            >
              <template #overlay>
                <div class="d-flex align-items-center justify-content-center mt-1">
                  <b-spinner
                    type="border"
                    variant="primary"
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p class="pt-1">
                    {{ $t('loading') }}
                  </p>
                </div>
              </template>
              <b-table
                ref="refUserListTable"
                class="position-relative"
                responsive
                :fields="headerTablePlayer"
                :items="membersIncompleteDataList.data"
                primary-key="id"
                show-empty
                :empty-text="$t('generic.noRecordsFound')"
              >

                <!-- Column: Avatar -->
                <template #cell(avatar_file)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    <span v-if="data.item.avatar_file">
                      <b-avatar
                        :src="data.item.avatar_file.url_thumb"
                        class="i-shadow-new"
                      />
                    </span>
                    <span v-else>
                      <b-avatar
                        class="i-shadow-new"
                      />
                    </span>
                  </span>
                </template>

                <!-- Column: Avatar -->
                <template #cell(full_name)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    {{ data.item.full_name }}
                  </span>
                </template>

                <!-- Column: status -->

                <template #cell(category)="data">
                  <span v-if="data.item.category != null">
                    {{ data.item.category.labelables[0].label }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <template #cell(jersey_number)="data">
                  <span v-if="data.item.jersey_number != null">
                    {{ data.item.jersey_number }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <template #cell(birthday)="data">
                  <span v-if="data.item.birthday != null">
                    {{ data.item.birthday | formatDate }}
                  </span>
                  <span v-else> N/A </span>
                </template>
                <!-- </b-table> -->

                <!-- Column: club name and team name -->
                <template #cell(team)="data">
                  <span v-if="data.item.team != null">
                    {{ data.item.team.name }} - {{ data.item.club.club_name }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <!-- Column: Status -->
                <template #cell(player_status)="data">
                  <span v-if="data.item.player_status != null">
                    <span v-if="data.item.player_status.key === 'ACTIVE'">
                      {{ data.item.player_status.labelables[0].label }}
                    </span>
                    <span v-else>
                      {{ data.item.player_status.labelables[0].label }} {{ data.item.ceding_club ? data.item.ceding_club : '' }}
                    </span>
                  </span>
                  <span v-else> N/A </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    {{ $t('Details') }}
                  </span>
                </template>
              </b-table>
            </b-overlay>
            <app-paginator
              :data-list="membersIncompleteDataList"
              @pagination-data="getIncompleteData"
            />
          </div>
          <!-- Member incompleto -->

          <!-- Cuerpo tecnico incompletos -->
          <div v-if="optionActiveD == selectCoachingStaff">
            <b-overlay
              :show="isLoadingIncompleted"
              opacity="1"
              :class="isLoadingIncompleted ? 'p-2 mb-1 mt-1' : ''"
            >
              <template #overlay>
                <div class="d-flex align-items-center justify-content-center mt-1">
                  <b-spinner
                    type="border"
                    variant="primary"
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p class="pt-1">
                    {{ $t('loading') }}
                  </p>
                </div>
              </template>
              <b-table
                ref="refUserListTable"
                class="position-relative"
                responsive
                :fields="headerTableCoachingStaff"
                :items="membersIncompleteDataList.data"
                primary-key="id"
                show-empty
                :empty-text="$t('generic.noRecordsFound')"
              >

                <!-- Column: Avatar -->
                <template #cell(avatar_file)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    <span v-if="data.item.avatar_file">
                      <b-avatar
                        :src="data.item.avatar_file.url_thumb"
                        class="i-shadow-new"
                      />
                    </span>
                    <span v-else>
                      <b-avatar
                        class="i-shadow-new"
                      />
                    </span>
                  </span>
                </template>

                <!-- Column: Avatar -->
                <template #cell(full_name)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    {{ data.item.full_name }}
                  </span>
                </template>

                <!-- Column: club name and team name -->
                <template #cell(team)="data">
                  <span v-if="data.item.team != null">
                    {{ data.item.team.name }} - {{ data.item.club.club_name }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <template #cell(birthday)="data">
                  <span v-if="data.item.birthday != null">
                    {{ data.item.birthday | formatDate }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <!-- Column: JobPosition -->
                <template #cell(staff_job_position)="data">
                  <span v-if="data.item.staff_job_position != null">
                    {{ data.item.staff_job_position.labelables[0].label }}
                  </span>
                  <span v-else-if="data.item.another_job != null">
                    {{ data.item.another_job }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    {{ $t('Details') }}
                  </span>
                </template>
              </b-table>
            </b-overlay>
            <app-paginator
              :data-list="membersIncompleteDataList"
              @pagination-data="getIncompleteData"
            />
          </div>
          <!-- End Cuerpo tecnico incompleto -->
        </app-collapse-item>

        <!-- Registrados -->
        <app-collapse-item
          :is-visible="true"
          :title="$t('registered-members')"
        >
          <div v-if="optionActiveD == selectPlayer">
            <b-overlay
              :show="isLoading"
              opacity="1"
              :class="isLoading ? 'p-2 mb-1 mt-1' : ''"
            >
              <template #overlay>
                <div class="d-flex align-items-center justify-content-center mt-1">
                  <b-spinner
                    type="border"
                    variant="primary"
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p class="pt-1">
                    {{ $t('loading') }}
                  </p>
                </div>
              </template>
              <b-table
                ref="refUserListTable"
                class="position-relative"
                responsive
                :fields="headerTablePlayer"
                :items="memberList.data"
                primary-key="id"
                show-empty
                :empty-text="$t('generic.noRecordsFound')"
              >

                <!-- Column: Avatar -->
                <template #cell(avatar_file)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    <span v-if="data.item.avatar_file">
                      <b-avatar
                        :src="data.item.avatar_file.url_thumb"
                        class="i-shadow-new"
                      />
                    </span>
                    <span v-else>
                      <b-avatar
                        class="i-shadow-new"
                      />
                    </span>
                  </span>
                </template>

                <!-- Column: Avatar -->
                <template #cell(full_name)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    {{ data.item.full_name }}
                  </span>
                </template>

                <!-- Column: status -->

                <template #cell(category)="data">
                  <span v-if="data.item.category != null">
                    {{ data.item.category.labelables[0].label }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <template #cell(jersey_number)="data">
                  <span v-if="data.item.jersey_number != null">
                    {{ data.item.jersey_number }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <!-- Column: club name and team name -->
                <template #cell(team)="data">
                  <span v-if="data.item.team != null">
                    {{ data.item.team.name }} - {{ data.item.club.club_name }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <template #cell(birthday)="data">
                  <span v-if="data.item.birthday != null">
                    {{ data.item.birthday | formatDate }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <!-- Column: Status -->
                <template #cell(player_status)="data">
                  <span v-if="data.item.player_status != null">
                    <span v-if="data.item.player_status.key === 'ACTIVE'">
                      {{ data.item.player_status.labelables[0].label }}
                    </span>
                    <span v-else>
                      {{ data.item.player_status.labelables[0].label }} {{ data.item.ceding_club ? data.item.ceding_club : '' }}
                    </span>
                  </span>
                  <span v-else> N/A </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    {{ $t('Details') }}
                  </span>
                </template>
              </b-table>
            </b-overlay>
            <app-paginator
              :data-list="memberList"
              @pagination-data="getMembers"
            />
          </div>

          <!-- Cuerpo tecnico -->
          <div v-if="optionActiveD == selectCoachingStaff">
            <b-overlay
              :show="isLoading"
              opacity="1"
              :class="isLoading ? 'p-2 mb-1 mt-1' : ''"
            >
              <template #overlay>
                <div class="d-flex align-items-center justify-content-center mt-1">
                  <b-spinner
                    type="border"
                    variant="primary"
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p class="pt-1">
                    {{ $t('loading') }}
                  </p>
                </div>
              </template>
              <b-table
                ref="refUserListTable"
                class="position-relative"
                responsive
                :fields="headerTableCoachingStaff"
                :items="memberList.data"
                primary-key="id"
                show-empty
                :empty-text="$t('generic.noRecordsFound')"
              >

                <!-- Column: Avatar -->
                <template #cell(avatar_file)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    <span v-if="data.item.avatar_file">
                      <b-avatar
                        :src="data.item.avatar_file.url_thumb"
                        class="i-shadow-new"
                      />
                    </span>
                    <span v-else>
                      <b-avatar
                        class="i-shadow-new"
                      />
                    </span>
                  </span>
                </template>

                <!-- Column: Avatar -->
                <template #cell(full_name)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    {{ data.item.full_name }}
                  </span>
                </template>
                <!-- Column: club name and team name -->
                <template #cell(team)="data">
                  <span v-if="data.item.team != null">
                    {{ data.item.team.name }} - {{ data.item.club.club_name }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <!-- Column: JobPosition -->
                <template #cell(staff_job_position)="data">
                  <span v-if="data.item.staff_job_position != null">
                    {{ data.item.staff_job_position.labelables[0].label }}
                  </span>
                  <span v-else-if="data.item.another_job != null">
                    {{ data.item.another_job }}
                  </span>
                  <span v-else> N/A </span>
                </template>
                <template #cell(birthday)="data">
                  <span v-if="data.item.birthday != null">
                    {{ data.item.birthday | formatDate }}
                  </span>
                  <span v-else> N/A </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <span
                    class="btn-cursor"
                    @click="
                      $router.push({
                        name: 'details-member',
                        params: { id: data.item.hash },
                      })
                    "
                  >
                    {{ $t('Details') }}
                  </span>
                </template>
              </b-table>
            </b-overlay>
            <app-paginator
              :data-list="memberList"
              @pagination-data="getMembers"
            />
          </div>
        </app-collapse-item>
      </app-collapse>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppCollapseItem from '@/views/member/components/app-collapse-member/AppCollapseItem.vue'
import AppCollapse from '@/views/member/components/app-collapse-member/AppCollapse.vue'
import checkPermission from '@/auth/permissions'
import FilterMember from './FilterMember.vue'
import AppPaginator from '../components/app-paginator/AppPaginator.vue'
import SelectModal from './SelectModal.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    FilterMember,
    AppPaginator,
    SelectModal,
  },
  data() {
    return {
      collapseType: 'margin',
      isLoading: false,
      isLoadingIncompleted: false,
      // filtersObj: {},
      tempQuery: {
        filtersObj: {},
        paramsObj: {
          page: 1,
          perPage: 15,
          included: 'team,club,category,memberType,playerStatus',
        },
      },
      // paramsObj: {
      //   page: 1,
      //   perPage: 15,
      //   included: 'team,club,category,memberType,playerStatus',
      // },
      sectionName: '',
      // Const
      optionActiveD: 'PLAYER',
      selectPlayer: 'PLAYER',
      selectCoachingStaff: 'COACHING_STAFF',
      typeMember: [{
        id: 0,
        name: this.$t('buttons.player'),
        query: 'PLAYER',
      },
      {
        id: 1,
        name: this.$t('buttons.coachingStaff'),
        query: 'COACHING_STAFF',
      }],
      // End Const
    }
  },
  computed: {
    ...mapGetters({
      role: 'role',
      memberList: 'memberStore/memberList',
      membersIncompleteDataList: 'memberStore/membersIncompleteDataList',
      member: 'memberStore/member',
      selectType: 'memberStore/selectType',
      categories: 'catalogs/categories',
      playerStatuses: 'catalogs/playerStatuses',
      staffJobPositions: 'catalogs/staffJobPositions',
      membersTypes: 'catalogs/membersTypes',
      clubInfo: 'clubInfo',
      colorPrimaryBtn: 'colorPrimaryBtn',
      countries: 'countries',
      states: 'states',
      clubs: 'clubList',
    }),
    headerTablePlayer() {
      return [
        {
          key: 'avatar_file',
          label: this.$t('generic.avatar'),
          sortable: false,
        },
        {
          key: 'full_name',
          label: this.$t('generic.name'),
          sortable: true,
        },
        {
          key: 'category',
          label: this.$t('generic.category'),
          sortable: true,
        },
        {
          key: 'jersey_number',
          label: this.$t('generic.numberMember'),
          sortable: true,
        },
        {
          key: 'birthday',
          label: this.$t('generic.birthday'),
          sortable: true,
        },
        {
          key: 'team',
          label: this.$t('generic.team'),
          sortable: true,
        },
        {
          key: 'player_status',
          label: this.$t('generic.status'),
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
    headerTableCoachingStaff() {
      return [
        {
          key: 'avatar_file',
          label: this.$t('generic.avatar'),
          sortable: false,
        },
        {
          key: 'full_name',
          label: this.$t('generic.name'),
          sortable: true,
        },
        {
          key: 'team',
          label: this.$t('generic.team'),
          sortable: true,
        },
        {
          key: 'birthday',
          label: this.$t('generic.birthday'),
          sortable: true,
        },
        {
          key: 'staff_job_position',
          label: this.$t('generic.jobPosition'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    '$i18n.locale': function i18nlocale() {
      this.fetchCountries()
      this.fetchPlayerStatuses()
      this.fetchStaffJobPositions()
      this.fetchCategories()
    },
  },
  created() {
    // this.fetchClubDataAll()
  },
  mounted() {
    if (this.checkPermission(['VIEW_MEMBERS_PLAYERS'])) {
      if (this.selectType == null) {
        this.tempQuery.filtersObj.member_type_key = this.selectPlayer
        this.tempQuery.filtersObj.is_complete_data = '1'
        this.optionActiveD = this.selectPlayer
      } else {
        this.tempQuery.filtersObj.member_type_key = this.selectType
        this.tempQuery.filtersObj.is_complete_data = '1'
        this.optionActiveD = this.selectType
      }

      this.sectionName = this.$t('buttons.player')
      this.getDataInitial()
    } else if (this.checkPermission(['VIEW_MEMBERS_COACHING_STAFF'])) {
      this.tempQuery.filtersObj.member_type_key = 'COACHING_STAFF'
      this.tempQuery.filtersObj.is_complete_data = '1'
      this.optionActiveD = 'COACHING_STAFF'
      this.sectionName = this.$t('buttons.coachingStaff')
      this.getDataInitial()
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },
  methods: {
    checkPermission, // Function de permisssions
    ...mapActions({
      getDataMembersCompleted: 'memberStore/getDataMembersCompleted',
      membersWhitIncompleteData: 'memberStore/membersWhitIncompleteData',
      fetchTeamByIdClub: 'teamStore/fetchTeamByIdClub',
      fetchClubs: 'fetchClubs',
      // Catalogs
      fetchCountries: 'fetchCountries',
      fetchCategories: 'catalogs/fetchCategories',
      fetchMembersTypes: 'catalogs/fetchMembersTypes',
      fetchStaffJobPositions: 'catalogs/fetchStaffJobPositions',
      fetchPlayerStatuses: 'catalogs/fetchPlayerStatuses',
    }),
    getDataInitial() {
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.tempQuery.filtersObj.club_hash = this.clubInfo.hash
      }

      this.getMembers()
      this.fetchCountries()
      // this.fetchCategories({ filtersObj: {}, paramsObj: {} })
      this.fetchCategories()
      this.fetchMembersTypes()
      this.fetchPlayerStatuses()
      this.fetchStaffJobPositions(this.tempQuery)
      // this.$nextTick(() => {
      //   if (this.corporateList.length === 1) {
      //     const c = this.corporateList[0].id || this.corp[0].id
      //     this.fetchTeamByIdClub(c)
      //   }
      // })
    },
    getMembersFilterReset() {
      this.tempQuery.paramsObj.page = 1
      this.tempQuery.paramsObj.perPage = 15
      this.tempQuery.filtersObj.club_hash = this.clubInfo.hash
      // this.filters.idClub = this.clubInfo.hash
      this.getMembers()
    },
    handleFilters(filterSelect) {
      if (filterSelect) {
        this.tempQuery.filtersObj = filterSelect
      }
      this.getMembers()
    },
    async getMembers(pagination) {
      this.isLoading = true
      this.isLoadingIncompleted = true
      if (pagination) {
        this.tempQuery.paramsObj.page = pagination.page
        this.tempQuery.paramsObj.perPage = pagination.perPage
      }
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.tempQuery.filtersObj.club_hash = this.clubInfo.hash
      }
      this.tempQuery.filtersObj.is_complete_data = '1'
      // this.tempQuery.paramsObj = this.paramsObj
      // this.tempQuery.filtersObj = this.filtersObj
      await this.getDataMembersCompleted(this.tempQuery).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
      await this.fetchClubs(this.tempQuery).then(() => {
      })
      await this.getIncompleteData()
    },
    async getIncompleteData() {
      this.tempQuery.filtersObj.is_complete_data = '0'
      // this.tempQuery.filtersObj = this.filtersObj
      await this.membersWhitIncompleteData(this.tempQuery).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      }).finally(() => {
        this.isLoadingIncompleted = false
      })
    },
    assignRouteY(pRoute) {
      if (pRoute === this.selectPlayer) {
        this.sectionName = this.$t('buttons.player')
        this.optionActiveD = pRoute
        this.tempQuery.filtersObj.is_complete_data = '1'
        this.tempQuery.filtersObj.member_type_key = pRoute
        this.$store.commit('memberStore/setSelectType', pRoute)
        this.resetFilterStaff()
        this.handleFilters()
      }
      if (pRoute === this.selectCoachingStaff) {
        this.sectionName = this.$t('buttons.coachingStaff')
        this.optionActiveD = pRoute
        this.tempQuery.filtersObj.is_complete_data = '1'
        this.tempQuery.filtersObj.member_type_key = pRoute
        this.$store.commit('memberStore/setSelectType', pRoute)
        this.resetFilterPlayer()
        this.handleFilters()
      }
    },
    resetFilterStaff() {
      this.tempQuery.filtersObj.country_hash = null
      this.tempQuery.filtersObj.state_hash = null
      this.tempQuery.filtersObj.staff_job_position_hash = null
    },
    resetFilterPlayer() {
      this.tempQuery.filtersObj.country_hash = null
      this.tempQuery.filtersObj.state_hash = null
      this.tempQuery.filtersObj.player_status_hash = null
    },
  },
}
</script>
<style scoped lang="scss">
.btn-cursor {
  cursor: pointer;
}
.mr-20 {
  margin-right: 20px !important;
  margin-top: 10px !important;
}
</style>
