<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("generic.filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- Search -->
        <b-col
          cols="12"
          class="mb-1"
        >
          <label>{{ $t("generic.search") }}</label>

          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="query.name"
              debounce="1000"
              :placeholder="$t('generic.search')"
            />
          </b-input-group>
        </b-col>
        <!-- End Search -->

        <b-col
          v-show="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="club-country-id">{{ $t('generic.country') }}</label>
          <v-select
            id="club-country-id"
            v-model="query.country_hash"
            label="name"
            :options="countries"
            :reduce="country => country.id"
            :placeholder="$t('generic.selectCountry')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          v-show="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="club-state-id">{{ $t('generic.state') }}</label>
          <v-select
            id="club-state-id"
            v-model="query.state_hash"
            label="name"
            :options="states"
            :disabled="query.country_hash == null"
            :reduce="city => city.id"
            :placeholder="$t('generic.selectState')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          v-show="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t("corporate.club") }}</label>
          <v-select
            v-model="query.club_hash"
            :placeholder="$t('corporate.selectSportClub')"
            label="club_name"
            :options="clubs"
            :reduce="club => club.hash"
            :disabled="query.state_hash == null"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          v-if="optionActiveD == selectPlayer"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t("generic.category") }}</label>
          <v-select
            v-model="query.category_hash"
            label="name"
            :placeholder="$t('generic.selectCategory')"
            :options="categories"
            :reduce="item => item.id"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          v-if="optionActiveD == selectPlayer"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t("generic.status") }}</label>
          <v-select
            v-model="query.player_status_hash"
            label="name"
            :placeholder="$t('generic.selectStatus')"
            :options="playerStatuses"
            :reduce="item => item.id"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          v-if="optionActiveD == selectCoachingStaff"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label> {{ $t('generic.jobPosition') }}</label>
          <v-select
            v-model="query.staff_job_position_hash"
            label="name"
            :placeholder="$t('jobPosition.selectJobPosition')"
            :options="staffJobPositions"
            :reduce="item => item.id"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'FilterJob',
  components: {
    vSelect,
  },
  props: {
    clubs: {
      type: Array,
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    states: {
      type: Array,
      default: null,
    },
    categories: {
      type: Array,
      default: null,
    },
    playerStatuses: {
      type: Array,
      default: null,
    },
    staffJobPositions: {
      type: Array,
      default: null,
    },
    optionActiveD: {
      type: String,
      default: 'PLAYER',
    },
    selectCoachingStaff: {
      type: String,
      default: 'COACHING_STAFF',
    },
    selectPlayer: {
      type: String,
      default: 'PLAYER',
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      query: {
        name: null,
        country_hash: null,
        state_hash: null,
        club_hash: null,
        player_status_hash: null,
        category_hash: null,
        staff_job_position_hash: null,
        member_type_key: null,
        is_complete_data: '1',
      },
    }
  },
  watch: {
    'query.country_hash': function querycountryHash(pCountry) {
      if (pCountry !== null) {
        this.fetchStateByCountryId(pCountry)
      }
      this.query.state_hash = null
      this.query.club_hash = null
      this.query.member_type_key = this.optionActiveD
      this.$emit('filter-query', this.query)
    },
    'query.state_hash': function queryStateHash() {
      this.query.club_hash = null
      this.query.member_type_key = this.optionActiveD
      this.$emit('filter-query', this.query)
    },
    'query.club_hash': function queryClubHash() {
      this.query.member_type_key = this.optionActiveD
      this.$emit('filter-query', this.query)
    },
    'query.player_status_hash': function queryPlayerStatusHash() {
      this.query.member_type_key = this.optionActiveD
      this.$emit('filter-query', this.query)
    },
    'query.name': function queryName() {
      this.query.member_type_key = this.optionActiveD
      this.$emit('filter-query', this.query)
    },
    'query.category_hash': function queryCategoryHash() {
      this.query.member_type_key = this.optionActiveD
      this.$emit('filter-query', this.query)
    },
    'query.staff_job_position_hash': function queryStaffJobPositionHash() {
      this.query.member_type_key = this.optionActiveD
      this.$emit('filter-query', this.query)
    },
  },
  mounted() {
    // this.$nextTick(async () => {
    //   if (this.corporates.length > 1) {
    //     this.query.idClub = null
    //   } else {
    //     this.query.idClub = this.corporates[0].id
    //   }
    // })
  },
  methods: {
    ...mapActions({
      fetchStateByCountryId: 'fetchStateByCountryId',
    }),
  },
}
</script>
