<template>
  <div>
    <!-- trigger button -->
    <div class="text-white">
      <b-button
        v-b-modal.modal-select
        class="btn-icon rounded-circle"
        variant="primary"
        :style="colorPrimaryBtn"
      >
        <feather-icon icon="UserIcon" />
      </b-button>
    </div>

    <!-- modal -->
    <!-- <div class="modal-select"> -->
    <b-modal
      id="modal-select"
      centered
      size="xs"
      :title="$t('member.create')"
      header-bg-variant="light"
      :header-text-variant="headerTextVariant"
      header-close-variant="dark"
      body-bg-variant="light"
      body-text-variant="light"
      hide-footer
    >
      <div
        v-for="item in membersTypes"
        :key="item.id"
      >
        <span
          v-if="checkPermission(['CREATE_MEMBERS_PLAYERS','ALLOW_ALL']) && item.key === 'PLAYER'"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="w-100 btn-member mb-1"
            variant="dark"
            @click="
              $router.push({
                name: 'create-member',
                query: { op: item.key, g: item.gender_hash}
              })
            "
          >
            <label class=" text-white">{{ item.name }}</label>
          </b-button>
        </span>
        <span
          v-if="checkPermission(['CREATE_MEMBERS_COACHING_STAFF','ALLOW_ALL']) && item.key === 'COACHING_STAFF'"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="w-100 btn-member mb-1"
            variant="dark"
            @click="
              $router.push({
                name: 'create-member',
                query: { op: item.key, g: item.gender_hash}
              })
            "
          >
            <label class=" text-white">{{ item.name }}</label>
          </b-button>
        </span>
      </div>

    </b-modal>
    <!-- </div> -->

  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    membersTypes: {
      type: Array,
      required: true,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      headerBgVariant: 'secondary',
      headerTextVariant: 'light',
    }
  },
}
</script>
<style>
.modal-select {
  color: white;
}
</style>
